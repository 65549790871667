
function Home() {
  return (
    <>
      <h1>Home</h1>
    
      
      <p>
        MR G
      </p>
     
    </>
  );
}

export default Home;