//Header.jsx
import { NavLink } from 'react-router-dom';
import React from 'react';
import './css/Header.css';

const Header = () => {
  return (
    <header className="header">
      <nav className="header_nav">
        <ul>
          <li><NavLink to="/" end>Home</NavLink></li>
          <li><NavLink to="/about">About</NavLink></li>
          <li><NavLink to="/contacts">Contacts</NavLink></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;